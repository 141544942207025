import { ADOBE_ANALYTICS, ROYALTY_REPORTING } from 'widget/trackers/constants';
import { AdobeAnalyticsTrackingComponent } from 'widget/trackers/analytics';
import { isEmpty, isObject } from 'lodash-es';
import { RoyaltyReporting } from 'widget/trackers/royaltyReporting';
import type { ComponentType, ReactElement } from 'react';
import type { TransportInterface } from 'widget/utils/transport/types';
import type { WidgetTrackingConfig } from 'widget/types';

const trackingMap: {
  [key: string]: ComponentType<any>;
} = {
  [ADOBE_ANALYTICS]: AdobeAnalyticsTrackingComponent,
};

function mapConfigToTrackers(
  config: WidgetTrackingConfig,
  transport: TransportInterface,
): ReactElement {
  const trackers = [
    <RoyaltyReporting key={ROYALTY_REPORTING} transport={transport} />,
  ];
  const stationType = config?.stationType;
  if (isObject(config) && !isEmpty(config)) {
    Object.keys(config).forEach((k: string) => {
      const trackingConfig = config![k];

      if (trackingMap[k] && trackingConfig.enabled) {
        const TrackingComponent = trackingMap[k];
        trackers.push(
          <TrackingComponent
            config={trackingConfig}
            key={k}
            stationType={stationType}
            transport={transport}
          />,
        );
      }
    });
  }

  return <>{trackers}</>;
}

export default mapConfigToTrackers;
