function createStopwatch(initialTime = 0) {
  // adds 0.5 seconds to internal counter, adjust as needed for granularity
  const toAdd = 1 / 2;
  const baseInterval = 1000 * toAdd;
  let interval = baseInterval;

  let multiplier = 1;

  let intervalRef: number | undefined;
  let time = initialTime;

  function stopwatchTick() {
    time += toAdd;
  }

  function stop() {
    window.clearInterval(intervalRef);
    intervalRef = undefined;
  }

  function start() {
    if (!intervalRef) {
      intervalRef = window.setInterval(stopwatchTick, interval);
    }
  }

  return {
    get() {
      return time;
    },
    reset() {
      stop();
      time = 0;
      multiplier = 1;
    },
    setMultiplier(n = 1) {
      multiplier = n;
      interval = baseInterval / multiplier;
      stop();
      start();
    },
    start,
    stop,
  };
}

export default createStopwatch;
