import baseLogger from 'widget/logger';
import createAuthHeaders from 'widget/services/utils/createAuthHeaders';
import Logger from 'modules/Logger/Logger';
import { AxiosError } from 'axios';
import { CONTEXTS } from 'modules/Logger';
import { get } from 'lodash-es';
import {
  InterceptorBlock,
  TransportInterface,
} from 'widget/utils/transport/types';
import { Session } from 'widget/utils/session';

const AMP_V1_AUTH_ERROR_CODE = 1;
const AMP_V2_AUTH_ERROR_CODE = 101;

/**
 * This interceptor will catch AMP unauthorized errors (session missing or expired)
 *
 * It will attempt to refresh the session cookies and retry the original request with
 * new session headers.
 */
export default function createSessionExpiredInterceptor(
  session: Session,
  transport: TransportInterface,
  logger: Logger = baseLogger,
): InterceptorBlock {
  return {
    response: {
      failure: async (e: AxiosError) => {
        const errorCode = get(e, ['response', 'data', 'error', 'code']);
        const statusCode = get(e, ['response', 'status']);

        if (
          statusCode === 401 ||
          (statusCode === 400 &&
            (errorCode === AMP_V1_AUTH_ERROR_CODE ||
              errorCode === AMP_V2_AUTH_ERROR_CODE))
        ) {
          let sessionInfo;

          try {
            sessionInfo = await session.fetchNewSession();
          } catch (err) {
            const requestUrl = get(e, ['config', 'url'], '');
            const errObj = new Error(
              `Failed to refresh session after a failed call to ${requestUrl}`,
            );
            logger.error(
              [CONTEXTS.TRANSPORT, CONTEXTS.WIDGET],
              errObj.message,
              {},
              errObj,
            );
            throw errObj;
          }

          // update original request headers with new session data
          e.config.headers = {
            ...e.config.headers,
            ...createAuthHeaders(sessionInfo),
          };

          // resend request
          return transport.getWrappedInstance().request(e.config);
        }

        throw e;
      },
    },
  };
}
