import { CONTEXTS } from 'widget/logger';
import type Analytics from 'modules/Analytics/Analytics';
import type Logger from 'modules/Logger/Logger';
import type { Session } from 'widget/utils/session';
import type { TransportInterface } from 'widget/utils/transport/types';

export type ABTestGroups = Record<string, 'A' | 'B' | undefined>;

export async function queryForABTestGroup({
  ampTransport,
  analytics,
  countryCode,
  lang,
  logger,
  session,
}: {
  ampTransport: TransportInterface;
  analytics: Analytics;
  countryCode: string;
  lang: string;
  logger: Logger;
  session: Session;
}): Promise<ABTestGroups> {
  try {
    const { profileId } = await session.getSession();

    const res = await ampTransport.post(
      '/api/v3/abtest/users/groups/query',
      {
        meta: {
          country: countryCode,
          deviceId: analytics.getDeviceId(),
          lang,
          platform: 'web',
        },
        userId: String(profileId),
      },
      {},
    );

    const groups = res?.data?.groups ?? {};

    analytics.set({
      user: {
        abTestGroup: Object.entries(groups).map(
          ([key, value]) => `${key}|${value}`,
        ),
      },
    });

    return groups;
  } catch (error: any) {
    const errObj = error instanceof Error ? error : new Error(error);
    logger.error([CONTEXTS.SERVICES, 'AB_TESTS'], error, {}, errObj);
    throw errObj;
  }
}
