import Link from 'widget/components/Link';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const ErrorTextLink = styled(Link)<Props>({
  fontSize: '1.2rem',
  lineHeight: 1.4,
  textDecoration: 'underline',
});

export default ErrorTextLink;
