import createRoyaltyReporter, {
  Props,
  RoyaltyReporter,
} from 'widget/trackers/royaltyReporting/createRoyaltyReporter';
import { noop } from 'lodash-es';

const noopInstance = [undefined, { unsubscribe: noop }];

/**
 * instanceCreator composes the creation of royalty reporter instances. a given
 * stream from AMP has a unique reportPayload for every given request. If a call
 * for creating a new instance of a royalty reporter is called with the same
 * unique reportPayload we'll return the current instance else we'll unsubscribe
 * the current instances and start a new instance
 */
let instance = noopInstance;
export default function instanceCreator(
  args: Props,
): RoyaltyReporter | undefined {
  const { reportPayload } = args;
  const [currentPayload, currentInstance] = instance;

  if (reportPayload === currentPayload) return currentInstance;

  currentInstance!.unsubscribe();

  const newInstance = createRoyaltyReporter(args);
  instance = [reportPayload, newInstance];

  return {
    ...newInstance,
    unsubscribe() {
      newInstance.unsubscribe();
      instance = noopInstance;
    },
  };
}
