export const LIVE = 'LIVE';
export const PLAYLIST = 'PLAYLIST';
export const PODCAST = 'PODCAST';
export const COLLECTION = 'COLLECTION';
export const ARTIST = 'ARTIST';
export const RADIO = 'RADIO';
export const FAVORITES = 'FAVORITES';

const playBackTypes: {
  [index: string]: string;
} = {
  ARTIST,
  COLLECTION,
  LIVE,
  FAVORITES,
  PLAYLIST,
  PODCAST,
  RADIO,
};

export default playBackTypes;
