import invariant from 'invariant';
import logger from 'widget/logger';
import { fetchLocationConfig } from 'widget/utils/services/fetchLocationConfig';
import { merge } from 'lodash-es';
import { TransportInterface } from 'widget/utils/transport/types';
import type { AdobeAnalyticsConfig } from 'widget/types/widgetTrackingConfig';
import type { Session } from 'widget/utils/session';

/**
 * Save analytics config to window
 *
 * If sessionId and profileId exist, it will fetch
 * subscription data as well.
 */
export async function saveAnalyticsConfigToWindow(
  session: Session,
  ampUrl: string,
  configEnv: string,
  countryCode: string,
  transport: TransportInterface,
  adobeAnalyticsConfig: AdobeAnalyticsConfig,
) {
  invariant(__CLIENT__, 'cannot call saveAnalyticsConfigToWindow() on server');
  const { profileId, sessionId } = await session.getSession();
  let user = {};

  if (profileId && sessionId) {
    const { subscription } = await fetchLocationConfig({
      ampUrl,
      configEnv,
      countryCode,
      logger,
      profileId,
      sessionId,
      transport,
    });

    user = {
      isTrialEligible: subscription!.isTrialEligible,
      profileId,
      skuPromotionType: subscription!.productId,
      subscriptionTier: subscription!.subscriptionType,
    };
  }

  window.analyticsData = merge({}, adobeAnalyticsConfig, {
    global: {
      user,
    },
  });
}
