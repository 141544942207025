import {
  SessionContext,
  SessionData,
} from 'widget/utils/session/contexts/SessionContext';
import type { ReactElement } from 'react';

type Props = {
  children: ReactElement;
  session: SessionData;
};

function SessionProvider({ children, session }: Props) {
  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
}

export default SessionProvider;
