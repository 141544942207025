import Analytics from 'modules/Analytics/Analytics';
import AnalyticsContext from 'widget/contexts/Analytics/AnalyticsContext';
import type { ReactElement } from 'react';

type Props = {
  analytics: Analytics;
  children: ReactElement;
};

function AnalyticsProvider(props: Props): ReactElement {
  return (
    <AnalyticsContext.Provider value={props.analytics}>
      {props.children}
    </AnalyticsContext.Provider>
  );
}

export default AnalyticsProvider;
