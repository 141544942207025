import PlaybackTypes from 'widget/constants/playback';
import { TrackingStationType } from 'widget/types/widgetTrackingConfig';
import type { TritonIds } from '.';

export const createCacheBust = () => Math.trunc(Math.random() * 9999999);

export const getCurrentTritonType = (
  currentType: keyof typeof PlaybackTypes,
): TrackingStationType =>
  ({
    [PlaybackTypes.ARTIST]: TrackingStationType.CUSTOM,
    [PlaybackTypes.COLLECTION]: TrackingStationType.CUSTOM,
    [PlaybackTypes.FAVORITES]: TrackingStationType.CUSTOM,
    [PlaybackTypes.LIVE]: TrackingStationType.CUSTOM,
    [PlaybackTypes.PLAYLIST]: TrackingStationType.CUSTOM,
    [PlaybackTypes.PODCAST]: TrackingStationType.TALK,
    [PlaybackTypes.RADIO]: TrackingStationType.CUSTOM,
  })[currentType];

export const getSid = (type: string, config?: TritonIds) =>
  config?.[getCurrentTritonType(type.toUpperCase())];
