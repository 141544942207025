import createAuthHeaders from 'widget/services/utils/createAuthHeaders';
import createTaggedUrl from 'api/createTaggedUrl';
import invariant from 'invariant';
import Logger from 'modules/Logger/Logger';
import safeStringify from 'utils/safeStringify';
import {
  ARTIST,
  COLLECTION,
  FAVORITES,
  PLAYLIST,
  RADIO,
} from 'widget/constants/playback';
import {
  RequestHeaders,
  RequestPayload,
  ResponsePayload,
} from 'widget/services/playback/reporting/types';
import { TransportInterface } from 'widget/utils/transport/types';

type Props = {
  ampUrl?: string;
  logger?: Logger;
  transport: TransportInterface;
};

function playbackReportingService({ ampUrl = '', transport }: Props) {
  return async function postPlaybackReporting(
    {
      modes = [],
      offline = false,
      playedDate = Date.now(),
      replay = false,
      reportPayload,
      secondsPlayed = 0,
      stationId,
      stationType,
      status,
    }: RequestPayload,
    sessionInfo: RequestHeaders,
  ): Promise<ResponsePayload> {
    invariant(
      reportPayload && typeof reportPayload === 'string',
      `reportPayload required, received: ${safeStringify(reportPayload)}`,
    );

    invariant(
      status && typeof status === 'string',
      `status required, received ${safeStringify(status)}`,
    );

    const [url, requestName] = createTaggedUrl`${{
      ampUrl,
    }}/api/v3/playback/reporting`;

    const stationTypeMap: {
      [key: string]: string;
    } = {
      [ARTIST]: RADIO,
      [FAVORITES]: RADIO,
      [PLAYLIST]: COLLECTION,
    };

    const { data } = await transport.post(
      url,
      {
        modes,
        offline,
        playedDate,
        replay,
        reportPayload,
        secondsPlayed: Math.floor(secondsPlayed),
        stationId,
        stationType: stationTypeMap[stationType as string] || stationType,
        status,
      },
      {
        headers: createAuthHeaders(sessionInfo),
        params: { requestName },
      },
    );

    return data;
  };
}

export default playbackReportingService;
