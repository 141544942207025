import cookie from 'js-cookie';
import logger, { CONTEXTS } from 'widget/logger';
import qs from 'qs';
import { memoize } from 'lodash-es';
import { TransportInterface } from 'widget/utils/transport/types';
import { v4 as uuid } from 'uuid';

const COOKIE_EXP_DAYS = 180;

type CookieConfig = {
  expires: number;
  path: string;
};

export default class Session {
  countryCode: string;

  deviceName: 'web-mobile' | 'web-desktop';

  transport: TransportInterface;

  constructor(
    countryCode: string,
    transport: TransportInterface,
    isMobile = false,
  ) {
    this.countryCode = countryCode;
    this.deviceName = isMobile ? 'web-mobile' : 'web-desktop';
    this.transport = transport;
  }

  async fetchNewSessionRemote() {
    /* AMP assigns unique profileIds based on oauthUuid, so we try to reuse it as much as possible */
    const oauthUuid = cookie.get('auuid') || uuid();
    const host = `webapp.${this.countryCode}`;

    const trackingParams = qs.stringify(
      {
        clientType: 'web',
        country: this.countryCode,
        pname: 'OrganicWeb',
        signupFlow: 'anon',
        uid: oauthUuid,
      },
      { delimiter: ';' },
    );

    const payload = {
      accessToken: 'anon',
      accessTokenType: 'anon',
      deviceId: oauthUuid,
      deviceName: this.deviceName,
      host,
      longProfileId: true,
      oauthUuid,
      userName: `anon${oauthUuid}`,
    };

    try {
      const { data } = await this.transport.post(
        `/api/v1/account/loginOrCreateOauthUser;${trackingParams}`,
        qs.stringify(payload),
        {},
      );
      const {
        profileId,
        sessionId,
        oauths,
      }: {
        oauths: Array<any>;
        profileId: string | number;
        sessionId: string;
      } = data;

      const finalProfileId = String(profileId);

      Session.setSessionCookies({
        oauthUuid: oauths[0].oauthUuid,
        profileId: finalProfileId,
        sessionId,
      });

      return { profileId: finalProfileId, sessionId };
    } catch (error: any) {
      const errObj =
        error instanceof Error ? error : (
          new Error(
            error?.message ?? 'Failed to create anonymous user session.',
          )
        );
      logger.error(CONTEXTS.REACT, errObj.message, {}, errObj);
      throw errObj;
    }
  }

  fetchNewSession = memoize(this.fetchNewSessionRemote);

  static setSessionCookies({
    oauthUuid,
    profileId,
    sessionId,
  }: {
    oauthUuid: string;
    profileId: string;
    sessionId: string;
  }) {
    /* these cookie names are copied from the web app's implementation of sessions */
    Session.setCookie('pid', profileId);
    Session.setCookie('aid', sessionId);
    Session.setCookie('auuid', oauthUuid);
  }

  static getSessionFromCookies() {
    const profileId: string | undefined = cookie.get('pid');
    const sessionId: string | undefined = cookie.get('aid');
    const oauthUuid: string | undefined = cookie.get('auuid');

    if (oauthUuid) {
      /* refresh auuid so we don't keep generating new ones when calling fetchNewSession() */
      Session.setCookie('auuid', oauthUuid);
    }

    if (profileId && sessionId) {
      return {
        profileId,
        sessionId,
      };
    }
    return null;
  }

  static setCookie(
    name: string,
    value: string | Record<string, unknown>,
    config: CookieConfig = { expires: COOKIE_EXP_DAYS, path: '/' },
  ) {
    cookie.set(name, value, config);
  }

  async getSession() {
    return Session.getSessionFromCookies() || this.fetchNewSession();
  }
}
