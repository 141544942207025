import { AxiosTransport } from 'widget/utils/transport/AxiosTransport';
import { MediaConfig } from 'widget/hooks/usePlayerState';

export function parsePlsFile(text: string) {
  const re = /File\d=(.*)/g;
  let match;
  const streamUrls: Array<MediaConfig> = [];

  do {
    match = re.exec(text);
    if (match) {
      // extract and load an actual aac payload which JW can understand
      streamUrls.push({
        file: match[1],
        preload: 'auto',
        type: 'aac',
      });
    }
  } while (match);
  return streamUrls;
}

export async function playPlsStation(
  streamUrl: string,
  transport: AxiosTransport,
  playLiveFunction: (media: Array<MediaConfig>) => void,
) {
  const { data: text } = await transport.get(streamUrl);
  const streamUrls = parsePlsFile(text);
  playLiveFunction(streamUrls);
}

export const isPlsFile = (streamUrl: string) => /.*\.pls/.test(streamUrl);
