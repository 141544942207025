import { Theme } from 'widget/styles/themes';
import { withTheme } from '@emotion/react';

type Props = {
  theme: Theme;
};

const IHeartIcon = ({ theme }: Props) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 20 16"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.55 0A5.5 5.5 0 0 0 10 2.5a5.38 5.38 0 0 0-8.4-.88A5.53 5.53 0 0 0 0 5.52c0 1.94 1.2 3.34 2.47 4.63l4.94 4.74a.4.4 0 0 0 .68-.3v-3.7c0-.5.2-1 .56-1.36a1.89 1.89 0 0 1 2.7 0c.35.36.55.85.55 1.37v3.69a.42.42 0 0 0 .25.37.4.4 0 0 0 .44-.08l4.93-4.73C18.8 8.85 20 7.46 20 5.53A5.6 5.6 0 0 0 16.64.42a5.38 5.38 0 0 0-2.1-.42zm-9.5 9.79a.38.38 0 0 1-.53.03C2.9 8.36 2.07 6.83 2.07 5.28v-.02c0-1.33.8-2.84 1.86-3.52a.37.37 0 0 1 .6.26.39.39 0 0 1-.2.4 3.7 3.7 0 0 0-1.5 2.86v.02c0 1.32.73 2.65 2.2 3.96a.38.38 0 0 1 .1.42.38.38 0 0 1-.08.13zm2.43-1.42a.37.37 0 0 1-.51.15 3.68 3.68 0 0 1-1.85-3.07v-.01A3.12 3.12 0 0 1 6.75 2.7a.37.37 0 0 1 .51.16.39.39 0 0 1-.16.51 2.3 2.3 0 0 0-1.23 2.06c0 .94.6 1.9 1.47 2.4.08.05.15.14.18.23.02.1.01.2-.04.3zM10 7.39a1.62 1.62 0 0 1-1.52-1.03 1.68 1.68 0 0 1 .36-1.8 1.63 1.63 0 0 1 2.8 1.17c0 .44-.18.86-.48 1.17-.31.31-.73.49-1.16.49zm3.03 1.12a.37.37 0 0 1-.51-.15.39.39 0 0 1 .14-.52 2.89 2.89 0 0 0 1.46-2.4 2.35 2.35 0 0 0-1.23-2.06.38.38 0 0 1-.18-.22.39.39 0 0 1 .25-.48.37.37 0 0 1 .29.03 3.06 3.06 0 0 1 1.63 2.72v.02c0 1.2-.75 2.44-1.85 3.06zm4.9-3.23c0 1.55-.83 3.08-2.46 4.54a.37.37 0 0 1-.53-.03.39.39 0 0 1 .03-.54c1.46-1.31 2.2-2.65 2.2-3.97v-.02a3.7 3.7 0 0 0-1.5-2.87.38.38 0 0 1-.1-.51.38.38 0 0 1 .5-.13 4.48 4.48 0 0 1 1.86 3.52v.01z"
      fill={theme.iheartIcon}
    />
  </svg>
);

export default withTheme(IHeartIcon);
