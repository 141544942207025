import Analytics from 'modules/Analytics/Analytics';
import Events from 'modules/Analytics/constants/events';
import invariant from 'invariant';
import Logger, { CONTEXTS } from 'widget/logger';
import pageView from 'modules/Analytics/helpers/pageView';
import { DataLayer, EventName } from 'modules/Analytics/types';
import { merge } from 'lodash-es';
import { TransportInterface } from 'widget/utils/transport/types';

/**
 * This callback sends a copy of all tracking data to Igloo (our internal data warehouse).
 *
 * It is meant to be used by our Analytics module, like so:
 *
 * Analytics.create({ onTrack: createOnTrackCallback(transport, logger) });
 */
export function createOnTrackCallback(
  transport: TransportInterface,
  logger: typeof Logger,
) {
  return async function onTrack(
    action: EventName,
    event: Record<string, any>,
    dataLayer: DataLayer,
  ) {
    if (!dataLayer) {
      throw new Error(
        'Could not send analytics data to Igloo because DataLayer is missing.',
      );
    }
    const { global, config } = dataLayer;
    const payload = merge(
      {},
      global,
      {
        action,
        event: {
          loggedTimestamp: Date.now(),
        },
      },
      event,
    );

    try {
      await transport.post(`${config.iglooUrl}/events`, payload, {});
    } catch (error: any) {
      const errObj =
        error instanceof Error ? error : (
          new Error(error?.message ?? 'Failed to post event to Igloo.')
        );
      logger.error([CONTEXTS.REACT], errObj.message, {}, errObj);
    }
  };
}

/**
 * Creates an Analytics instance. To be used client-side only.
 */
function createAnalytics(transport: TransportInterface, logger: typeof Logger) {
  invariant(__CLIENT__, 'Analytics should be initialized on client only.');

  const onTrack = createOnTrackCallback(transport, logger);
  const analytics = Analytics.create({ onTrack, timeout: 240000 });

  analytics.trackPageView = async data =>
    analytics.track(Events.PageView, pageView(data));

  return analytics;
}

export default createAnalytics;
