import axios, { AxiosInstance, AxiosResponse } from 'axios';
import qs from 'qs';
import {
  BaseConfig,
  Config,
  Interceptors,
  RequestData,
  TransportInterface,
} from 'widget/utils/transport/types';
import { identity, mergeWith, omit } from 'lodash-es';

// merger concats arrays and adds properties to objects as needed
function merger(objVal?: Array<any>, srcVal?: any): void | Array<any> {
  if (Array.isArray(objVal)) {
    return objVal.concat(srcVal);
  }

  return undefined;
}

// paramsSerializer drops `requestName` from outgoing query parameters; this is
// done as a hack to make flow happy as appending `requestName` to
// AxiosXHRConfigs is a pita.
function _paramsSerializer(params: any) {
  return qs.stringify(omit(params, ['requestName']));
}

export class AxiosTransport implements TransportInterface {
  baseConfig: BaseConfig;

  _axios: AxiosInstance;

  static paramsSerializer = _paramsSerializer;

  constructor(baseConfig: BaseConfig = {}, interceptors?: Interceptors) {
    this.baseConfig = {
      paramsSerializer: _paramsSerializer,
      ...baseConfig,
    };

    this._axios =
      Array.isArray(interceptors) && interceptors.length ?
        AxiosTransport.bindInterceptors(interceptors)
      : axios;
  }

  get(url: string, config?: Config): Promise<AxiosResponse> {
    return this._axios.get(url, mergeWith({}, this.baseConfig, config, merger));
  }

  post(
    url: string,
    data: RequestData,
    config?: Config,
  ): Promise<AxiosResponse> {
    return this._axios.post(
      url,
      data,
      mergeWith({}, this.baseConfig, config, merger),
    );
  }

  getWrappedInstance() {
    return this._axios;
  }

  static bindInterceptors(
    interceptors: Interceptors,
    instance: AxiosInstance = axios.create(),
  ): AxiosInstance {
    interceptors.forEach(({ request, response } = {}) => {
      instance.interceptors.request.use(
        (request && request.success) || identity,
        (request && request.failure) || Promise.reject,
      );

      instance.interceptors.response.use(
        (response && response.success) || identity,
        (response && response.failure) || Promise.reject,
      );
    });

    return instance;
  }
}
