import toBoolean from 'widget/utils/toBoolean';
import { Events } from '../types';
import { loadScript } from '@iheartradio/web.utilities';
import type { ComScoreBeaconPayload } from 'global';
import type { EventTypeMap } from '../types';
import type { TrackerConfig } from '@iheartradio/web.signal';

/*
 * comScoreTracker fires on page-view by downloading its own SDK, mounting a
 * beacon, and eventually calling a tracking pixel. Our job is to append our
 * sessionID, our ComScore customerID, and let the beacon harvest the rest of the
 * needed information for itself.
 *
 * the tracking pixel's call signature will look something like:
 * https://sb.scorecardresearch.com/b?c1=foo&c2=bar
 *
 * docs here:
 * https://direct.comscore.com/clients/docs/comScoreImplementationSpecs-Publishers.pdf
 */

export const comScoreTracker = (
  enabled: boolean,
  customerId: string,
  hasOptedOut?: boolean,
): TrackerConfig<EventTypeMap> => {
  const comScoreMessage: ComScoreBeaconPayload = {
    c1: '2',
    c2: customerId,
    cs_ucfr: hasOptedOut ? '0' : '1',
  };
  /**
   * IHRWEB-15531 - Widgets embedded within the IHR network
   * are causing duplicate comscore events. If ihrnetwork=true query string is in the request, do not load com_score tracker
   */

  const query = new URLSearchParams(document.location.search);
  const ihrnetwork = toBoolean(query.get('ihrnetwork')) ?? false;
  /**
   * ComScore should be enabled if:
   * - it is `enabled` in the config
   * - config `customerId` value is set
   * - `ihrnetwork` query param is falsy
   */
  const comScoreEnabled = enabled && !!customerId && !ihrnetwork;

  return {
    enabled: comScoreEnabled,
    name: 'ComScore',
    initialize: async () => {
      comScoreMessage.options = {
        enableFirstPartyCookie: true,
        bypassUserConsentRequirementFor1PCookie: !hasOptedOut,
      };

      await loadScript(
        `https://sb.scorecardresearch.com/cs/${customerId}/beacon.js`,
        { async: true },
      );
    },
    events: {
      [Events.PageView]: ({ profileId }) => {
        window.COMSCORE.beacon({
          ...comScoreMessage,
          cs_xi: String(profileId),
        });
      },
    },
  };
};
