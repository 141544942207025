import HeaderIcon from 'widget/components/HeaderIcon';
import IHeartIcon from 'widget/components/IHeartLink/primitives/IHeartIcon';

type Props = {
  className?: string;
  href?: string;
  title?: string;
};

function IHeartLink({
  href = 'https://www.iheart.com/',
  title = 'Visit iheart.com',
  className,
}: Props) {
  return (
    <HeaderIcon className={className}>
      <a
        aria-label="iHeart.com link"
        data-test="iheart-link"
        href={href}
        rel="noreferrer noopener"
        target="_blank"
        title={title}
      >
        <IHeartIcon />
      </a>
    </HeaderIcon>
  );
}

export default IHeartLink;
