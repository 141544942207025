import IHeartLink from 'widget/components/IHeartLink';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
  href?: string;
  title?: string;
};

const ErrorIHeartLink = styled(IHeartLink)<Props>({
  position: 'absolute',
  right: '1rem',
  top: '1rem',
});

export default ErrorIHeartLink;
