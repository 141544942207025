import player from 'widget/player';
import PlayerState, {
  PlayerErrorState,
  PlayerWarningState,
} from 'modules/Player/constants/playerState';
import useMount from 'hooks/useMount';
import { ERROR_SUB_TYPES } from 'modules/Player/middleware/processErrors/constants';
import { memoize } from 'lodash-es';
import { ProcessedError } from 'modules/Player/middleware/processErrors/types';
import { useState } from 'react';

const BLANK_MP4 = 'https://web-static.pages.iheart.com/jw-player/blank.mp4';

export type JWEvent = {
  item: {
    allSources?: Array<Record<string, unknown>>;
    dvrSeekLimit?: number;
    feedData?: Record<string, any>;
    file?: string;
    minDvrWindow?: number;
    preload?: string;
    sources?: Array<Record<string, unknown>>;
    tracks?: Array<Record<string, unknown>>;
    type?: string;
  };
  playReason?: string;
  type?: string;
};

export type MediaConfig = {
  file: string;
  preload?: 'metadata' | 'auto' | 'none';
  type: string;
};

export default function usePlayerState(): [
  PlayerState | PlayerErrorState | PlayerWarningState,
  (media: string | Array<MediaConfig>) => void,
  (media: string | Array<MediaConfig>) => void,
] {
  const [loaded, setLoad] = useState(false);
  const [playerState, setPlayerState] = useState<
    PlayerState | PlayerErrorState | PlayerWarningState
  >(PlayerState.Idle);
  let isPlayerWarmup = false;

  /** Beacuse jwplayer has a warmup cycle that requires us to
   *  first play a 'blank.mp4' file, this function checks for jwplayer
   *  events for the blank.mp4 and prevents them from being sent
   */
  function stateCheck(state: PlayerState, stateEvent: JWEvent) {
    if (state === PlayerState.PlayAttempt) {
      const { file } = stateEvent.item;
      if (file === BLANK_MP4) {
        isPlayerWarmup = true;
        return;
      } else {
        isPlayerWarmup = false;
      }
    }

    if (state === PlayerState.Playing && !isPlayerWarmup) {
      // TODO: fix this at the jwplayer intereface level
      setPlayerState(PlayerState.Playing);
    }
  }

  const logAndSetPlayerState = memoize(
    ({ source, subType } = {} as ProcessedError) => {
      setPlayerState(subType || ERROR_SUB_TYPES.DEFAULT);
      // eslint-disable-next-line no-console
      console.error(subType, source);
    },
  );

  useMount(() =>
    player.subscribe({
      buffer: () => setPlayerState(PlayerState.Buffering),
      complete: () => setPlayerState(PlayerState.Complete),
      error: logAndSetPlayerState,
      idle: () => setPlayerState(PlayerState.Idle),
      pause: () => setPlayerState(PlayerState.Paused),
      play: () => stateCheck(PlayerState.Playing, { item: { file: '' } }),
      playAttempt: e => stateCheck(PlayerState.PlayAttempt, e),
      warning: logAndSetPlayerState,
    }),
  );

  function loadMedia(media: string | Array<MediaConfig>) {
    player.load(
      typeof media === 'string' ?
        [
          {
            file: media,
            preload: 'auto',
            type: 'mp3',
          },
        ]
      : media,
    );
  }

  function togglePlay(mediaUrl: string | Array<MediaConfig>) {
    if (playerState === PlayerState.Playing) {
      player.pause();
    } else {
      if (!loaded) {
        setPlayerState(PlayerState.Loading);
        loadMedia(mediaUrl);
        setLoad(true);
      }
      player.play();
    }
  }

  function loadNewTrack(mediaUrl: string | Array<MediaConfig>) {
    loadMedia(mediaUrl);
    player.play();
  }

  return [playerState, loadNewTrack, togglePlay];
}
