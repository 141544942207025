import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
  shrink?: boolean;
};

const Container = styled('div')<Props>(({ shrink = false, theme }) => ({
  background: theme.backgroundPrimary,
  border: theme.border,
  borderRadius: '0.6rem',
  boxSizing: 'border-box',
  color: theme.fontPrimary,
  height: shrink ? `${window.innerHeight}px` : '100vh',
  overflow: 'hidden',
  position: 'relative',
  transform: 'translateY(0)', // IHRWEB-15513: This is a hacky solution to fix an issue where the player extended past the widget's bottom border
}));

export default Container;
