import useMount from 'hooks/useMount';
import useTrackers from 'widget/contexts/Trackers/hooks/useTrackers';
import { Events } from 'widget/trackers/types';
import { SessionContext } from 'widget/utils/session/contexts/SessionContext';
import { useContext } from 'react';
import type { SessionData } from 'widget/utils/session/contexts/SessionContext';

const useTrackPageViewOnMount = (trackingData?: any) => {
  const session = useContext<SessionData>(SessionContext);
  const trackers = useTrackers();

  useMount(() => {
    trackers.track(Events.PageView, { ...session, ...trackingData });
  });
};

export default useTrackPageViewOnMount;
