import { createContext } from 'react';

type DefaultValues = {
  isReady: boolean;
  setReadyState: (val: boolean) => void;
};

const AnalyticsContext = createContext<DefaultValues>({
  isReady: false,
  setReadyState(val = true): void {
    this.isReady = val;
  },
});

export default AnalyticsContext;
