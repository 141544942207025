import colors from 'styles/colors';
import { DARK_THEME, LIGHT_THEME } from './constants';

export type Theme = {
  activeText?: string;
  backgroundPrimary?: string;
  backgroundSecondary?: string;
  backgroundTertiary?: string;
  border?: string;
  confirmation?: string;
  filledProgressBars?: string;
  fontPrimary?: string | undefined;
  fontSecondary?: string;
  fontTertiary?: string;
  highlightOutline?: Array<string> | string;
  iheartIcon?: string;
  iheartLogo?: string;
  liveThumb?: string;
  modalBackground?: string;
  progressBars?: string;
  timeColor?: string;
  thumbBoxShadow?: string;
};

export type Themes = {
  [key: string]: Theme;
  [index: number]: Theme;
};

const themes: Themes = {
  [DARK_THEME]: {
    activeText: colors.red['400'],
    backgroundPrimary: colors.gray['550'],
    backgroundSecondary: colors.gray['500'],
    backgroundTertiary: colors.gray['600'],
    border: 'none',
    confirmation: colors.green['300'],
    filledProgressBars: colors.red['400'],
    fontPrimary: colors.white.primary,
    fontSecondary: colors.gray['300'],
    fontTertiary: colors.gray['300'],
    highlightOutline: [
      '.5rem auto -webkit-focus-ring-color;',
      '.5rem auto Highlight;',
    ],
    iheartIcon: colors.gray['400'],
    iheartLogo: colors.white.primary,
    liveThumb: colors.white.primary,
    modalBackground: colors.black.dark,
    progressBars: colors.gray['400'],
    thumbBoxShadow: 'none',
    timeColor: colors.gray['300'],
  },
  [LIGHT_THEME]: {
    activeText: colors.red['500'],
    backgroundPrimary: colors.white.primary,
    backgroundSecondary: colors.gray['200'],
    backgroundTertiary: colors.gray['100'],
    border: `0.1rem solid ${colors.gray['300']}`,
    confirmation: colors.green['600'],
    filledProgressBars: colors.red['500'],
    fontPrimary: colors.gray['600'],
    fontSecondary: colors.gray['400'],
    fontTertiary: colors.gray['500'],
    highlightOutline: [
      '.5rem auto -webkit-focus-ring-color;',
      '.5rem auto Highlight;',
    ],
    iheartIcon: colors.gray['300'],
    iheartLogo: 'fullColor',
    liveThumb: colors.white.primary,
    modalBackground: colors.white.primary,
    progressBars: colors.gray['300'],
    thumbBoxShadow: `${colors.gray['300']} 2px 3px 10px 0px`,
    timeColor: colors.gray['400'],
  },
};

export default themes;
