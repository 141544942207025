import styled from '@emotion/styled';
import type { Theme } from 'widget/styles/themes';

type Props = {
  'data-test'?: string;
};

const Link = styled('a')<Props>(({ theme }: { theme: Theme }) => ({
  '& svg': {
    color: theme.fontPrimary,
    display: 'block',
    fill: theme.fontPrimary,
  },
  ':focus': {
    outline: theme.highlightOutline,
  },
  color: theme.fontPrimary,
  display: 'inline-block',
  textDecoration: 'none',
}));

export default Link;
