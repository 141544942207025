import Logger from 'widget/logger';
import qs from 'qs';
import type { TransportInterface } from 'widget/utils/transport/types';

export type Subscription = {
  entitlements: Array<string>;
  expirationDate?: number;
  hasBillingHistory: boolean;
  isAutoRenewing: boolean;
  isEligibleForAnotherTrial: boolean;
  isTrial: boolean;
  isTrialEligible: boolean;
  offlineExpirationDate?: number;
  productId?: string;
  promosRedeemed: Array<string>;
  source?: string;
  subscriptionType: 'PREMIUM' | 'FREE';
  terminationDate?: number;
};

export type LocationConfig = {
  subscription?: Subscription;
};

const VERSION = 8;

type Props = {
  ampUrl: string;
  configEnv: string;
  countryCode: string;
  logger?: typeof Logger;
  profileId: string;
  sessionId: string;
  transport: TransportInterface;
};

export async function fetchLocationConfig({
  transport,
  ampUrl,
  countryCode,
  configEnv,
  profileId,
  sessionId,
}: Props): Promise<LocationConfig> {
  const query = qs.stringify({
    countryCode,
    hostname: 'webapp',
    version: `${VERSION}-${configEnv}`,
  });

  const { data } = await transport.get(
    `${ampUrl}/api/v3/locationConfig?${query}`,
    {
      headers: {
        'X-Ihr-Profile-Id': profileId,
        'X-Ihr-Session-Id': sessionId,
        'X-Session-Id': sessionId,
        'X-User-Id': profileId,
      },
    },
  );

  return data;
}
