import Player from 'widget/contexts/Player';
import royaltyTracker from 'widget/trackers/royaltyReporting/tracker';
import useMount from 'hooks/useMount';
import { noop } from 'lodash-es';
import { SessionContext } from 'widget/utils/session/contexts/SessionContext';
import { useContext } from 'react';
import type { SessionData } from 'widget/utils/session/contexts/SessionContext';
import type { TransportInterface } from 'widget/utils/transport/types';

type Props = {
  transport: TransportInterface;
};

function RoyaltyReporting({ transport }: Props): null {
  const session = useContext<SessionData>(SessionContext);

  const { getCurrentlyPlaying, setReportingPayload } = useContext(
    Player.Context,
  );

  let tracker: any = { unsubscribe: noop };

  const currentlyPlaying = getCurrentlyPlaying();

  if (currentlyPlaying) {
    const { isSkip, playbackType, reportPayload, stationId, trackId } =
      currentlyPlaying;

    tracker = royaltyTracker({
      session,
      isSkip,
      reportPayload,
      setReportingPayload,
      stationId,
      stationType: playbackType,
      trackId,
      transport,
    });
  }

  useMount(() => () => {
    tracker.unsubscribe();
  });

  return null;
}

export default RoyaltyReporting;
