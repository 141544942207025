type ProfileId = string;

type SessionId = string;

type SessionInfo = {
  profileId: ProfileId;
  sessionId: SessionId;
};

type AuthHeaders = {
  'X-Ihr-Profile-Id': ProfileId;
  'X-Ihr-Session-Id': SessionId;
  'X-Session-Id': SessionId;
  'X-User-Id': ProfileId;
};

function createAuthHeaders({ profileId, sessionId }: SessionInfo): AuthHeaders {
  return {
    'X-Ihr-Profile-Id': profileId,
    'X-Ihr-Session-Id': sessionId,
    'X-Session-Id': sessionId,
    'X-User-Id': profileId,
  };
}

export default createAuthHeaders;
