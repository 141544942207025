import Container from 'widget/components/Container';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const ErrorContainer = styled(Container)<Props>({
  padding: '2rem',
  position: 'absolute',
  textAlign: 'center',
  top: 0,
  width: '100%',
});

export default ErrorContainer;
