import { AppConfig } from 'widget/types';

export type ComScoreConfig = {
  customerId: string;
  enabled: boolean;
  script?: string;
};

export enum TrackingStationType {
  CUSTOM = 'custom',
  TALK = 'talk',
}

export type AdobeAnalyticsConfig = {
  config: {
    dtmUrl: AppConfig['sdks']['adobeAnalytics']['dtmUrl'];
    iglooUrl: AppConfig['urls']['iglooUrl'];
  };
  enabled: boolean;
  global: {
    device: {
      appVersion: AppConfig['version'];
      env: AppConfig['env'];
      host: AppConfig['hostName'];
      language: string;
    };
  };
  user?: {
    isTrialEligible: boolean;
    registration: {
      country: string;
    };
    subscriptionTier: string;
  };
};

// any param in this map should intersect the Enabled type
export type WidgetTrackingConfig = {
  adobeAnalytics?: AdobeAnalyticsConfig;
  comScore?: ComScoreConfig;
  [key: string]: any;
};
