import type { SessionData } from 'widget/utils/session/contexts/SessionContext';

/* eslint-disable camelcase */
type StationTypeValue =
  | 'album'
  | 'artist'
  | 'collection'
  | 'custom'
  | 'episode'
  | 'favorites'
  | 'featured'
  | 'live'
  | 'mymusic'
  | 'playlistradio'
  | 'podcast'
  | 'song'
  | 'talk'
  | 'track';

export enum Events {
  AAPreview = 'aa_preview',
  Back15 = 'back_15',
  Click = 'click',
  CreateContent = 'create_content',
  Error = 'error',
  FailedSubscription = 'failed_subscription',
  FollowArtist = 'follow_artist',
  FollowPodcast = 'follow_podcast',
  FollowPlaylist = 'follow_playlist',
  FollowUnfollow = 'follow_unfollow',
  Forward30 = 'forward_30',
  GenreUpdate = 'genre_update',
  InAppMessageExit = 'iam_exit',
  InAppMessageOpen = 'iam_open',
  ItemSelected = 'item_selected',
  ListenTime = 'listen_time',
  Logout = 'logout',
  LogoutForced = 'logout_forced',
  Mute = 'mute',
  OpenClosePlayer = 'full_player_open_close',
  PageView = 'page_view',
  Password = 'password_action',
  Pause = 'pause',
  PaymentFrameExit = 'payment_frame_exit',
  PaymentFrameOpen = 'payment_frame_open',
  Play = 'play',
  PlaybackError = 'playback_error',
  RegGateExit = 'reg_gate_exit',
  RegGateOpen = 'reg_gate_open',
  Replay = 'replay',
  Rewind = 'rewind',
  SaveDelete = 'save_delete',
  SaveStation = 'save_station',
  Search = 'search',
  Share = 'share',
  Shuffle = 'shuffle',
  Skip = 'skip',
  SpeedChange = 'speed_change',
  Stop = 'stop',
  StreamEnd = 'stream_end',
  StreamFallback = 'stream_fallback',
  StreamStart = 'stream_start',
  Subscribe = 'subscribe',
  Thumbing = 'thumbing',
  ThumbsUp = 'thumbs_up',
  ThumbsDown = 'thumbs_down',
  UnfollowArtist = 'unfollow_artist',
  UnfollowPodcast = 'unfollow_podcast',
  UnfollowPlaylist = 'unfollow_playlist',
  Unmute = 'unmute',
  UnsaveStation = 'unsave_station',
  UnthumbUp = 'unthumb_up',
  UnthumbDown = 'unthumb_down',
  UpsellExit = 'upsell_exit',
  UpsellOpen = 'upsell_open',
  ViewContent = 'view_content',
}

type FollowUnfollowArtist = {
  artist_name: string;
  artistId: string | number;
};

type FollowUnfollowPlaylist = {
  playlistId: string | number;
  playlist: string;
};

type FollowUnfollowPodcast = {
  podcast: string;
  podcastId: string | number;
};

type SaveUnsaveStation = {
  name: string;
  type: StationTypeValue;
  identifier: string | number;
};

type StreamStart = {
  id?: string;
  name?: string;
  type?: string;
  typeId?: number;
};

type ThumbsAction = {
  artistName: string;
  artistId: string | number;
  trackName: string;
  stationType: StationTypeValue;
};

// Definitions for each event and the data the handler can expect to receive
export type EventTypeMap = {
  [Events.AAPreview]: {
    curated?: boolean;
    isTrialEligible?: boolean;
    subscriptionType?: string;
    playedFromTrigger?: string;
  };
  [Events.FollowArtist]: FollowUnfollowArtist;
  [Events.FollowPlaylist]: FollowUnfollowPlaylist;
  [Events.FollowPodcast]: FollowUnfollowPodcast;
  [Events.Mute]: null;
  [Events.PageView]: SessionData;
  [Events.Pause]: unknown;
  [Events.Play]: StreamStart;
  [Events.RegGateExit]: {
    optIn?: boolean;
  };
  [Events.SaveStation]: SaveUnsaveStation;
  [Events.Share]: unknown;
  [Events.Stop]: null;
  [Events.StreamEnd]: unknown;
  [Events.StreamStart]: StreamStart;
  [Events.Subscribe]: {
    id: string | number;
    name: string;
    type: StationTypeValue;
  };
  [Events.ThumbsDown]: ThumbsAction;
  [Events.ThumbsUp]: ThumbsAction;
  [Events.UnfollowArtist]: FollowUnfollowArtist;
  [Events.UnfollowPlaylist]: FollowUnfollowPlaylist;
  [Events.UnfollowPodcast]: FollowUnfollowPodcast;
  [Events.Unmute]: {
    isWarmingUp: boolean;
    isPlaying: boolean;
  };
  [Events.UnsaveStation]: SaveUnsaveStation;
  [Events.UnthumbDown]: ThumbsAction;
  [Events.UnthumbUp]: ThumbsAction;
};

export type EventsHandler = <K extends keyof EventTypeMap>(
  eventName: K,
  eventPayload: EventTypeMap[K],
) => void;

export type MappedEventHandlers = Partial<{
  [P in keyof EventTypeMap]: (payload: EventTypeMap[P]) => void;
}>;
