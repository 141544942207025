import PlayerState, {
  PlayerErrorState,
  PlayerWarningState,
} from 'modules/Player/constants/playerState';
import { createContext } from 'react';
import { noop } from 'lodash-es';
import { Request } from 'express';
import type {
  GetCurrentlyPlaying,
  RequestQuery,
} from 'widget/contexts/Player/types';
import type { ResponsePayload } from 'widget/services/playback/reporting/types';

type DefaultValue = {
  getCurrentlyPlaying: GetCurrentlyPlaying | typeof noop;
  play: (
    stationId: string,
    opts?: {
      streamUrlQuery?: Request['query'];
      trackId?: string | null;
      trackIds?: Array<number>;
    },
  ) => void;
  playArtistStation: (stationId: string) => void;
  playFavoritesStation: (stationId: string) => void;
  playLiveStation: (
    id: string,
    stream: { type: string; url: string },
    isFallback?: boolean,
  ) => void;
  playerState: PlayerState | PlayerErrorState | PlayerWarningState;
  playNextArtistRadio: (
    stationId: string,
    isSkip: boolean,
  ) => Promise<boolean | Error>;
  playNextFavoritesRadio: (
    stationId: string,
    isSkip: boolean,
  ) => Promise<boolean | Error>;
  playNextPlaylistRadio: (
    stationId: string,
    isSkip: boolean,
    trackIds?: Array<number>,
  ) => Promise<boolean | Error>;
  playNextPodcast: (
    podcastId: string,
    origQuery: RequestQuery,
  ) => Promise<boolean | Error>;
  skipDisabled: boolean;
  setReportingPayload: (res: ResponsePayload) => void;
};

const PlayerContext = createContext<DefaultValue>({
  getCurrentlyPlaying: noop,
  play: noop,
  playArtistStation: noop,
  playerState: PlayerState.Idle,
  playFavoritesStation: noop,
  playLiveStation: noop,
  playNextArtistRadio: () => Promise.resolve(true),
  playNextFavoritesRadio: () => Promise.resolve(true),
  playNextPlaylistRadio: () => Promise.resolve(true),
  playNextPodcast: () => Promise.resolve(true),
  skipDisabled: true,
  setReportingPayload: noop,
});

export default PlayerContext;
