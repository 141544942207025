import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const HeaderIcon = styled('div')<Props>({
  position: 'absolute',
  right: '1rem',
  [mediaQueryBuilder(mediaQueries.max.height['99'])]: {
    display: 'none',
  },
});

export default HeaderIcon;
