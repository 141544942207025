import createStopwatch from 'widget/trackers/royaltyReporting/stopwatch';
import invariant from 'invariant';
import playbackMap from 'widget/constants/playback';
import playbackReporting, {
  DONE,
  REPORT_15,
  ReportingStatusEnum,
  ResponsePayload,
  SKIP,
  START,
} from 'widget/services/playback/reporting';
import player from 'widget/player';
import { TransportInterface } from 'widget/utils/transport/types';

type SessionInfo = {
  profileId: string;
  sessionId: string;
};

interface RequestPayload {
  reportPayload: any;
  stationId: string | number;
  stationType: keyof typeof playbackMap;
}

export interface Props extends RequestPayload {
  session: SessionInfo;
  isSkip?: boolean | null;
  setReportingPayload: (res: ResponsePayload) => void;
  transport: TransportInterface;
  trackId: string;
}

export type RoyaltyReporter = {
  unsubscribe: (...args: Array<any>) => void | undefined;
};

/**
 * createRoyaltyReporter subscribes to player updates and manages reporting
 * needed playback states to AMP. A given status for a particular
 * stationId/reportPayload from the v2/playback/streams endpoint should only be
 * reported once (ex: PLAY only reported on first play, not after a pause).
 *
 * There shoud only ever be a single instance of this object in memory at any
 * point to prevent both memory leaks and faulty reporting for a given
 * reportPayload
 */
let isMounted = false;
function createRoyaltyReporter({
  session,
  isSkip,
  reportPayload,
  setReportingPayload,
  stationId,
  stationType,
  transport,
}: Props): RoyaltyReporter {
  invariant(
    __CLIENT__,
    'createRoyaltyReporter should only be mounted within the browser',
  );

  invariant(
    !isMounted,
    'only one instance of royalty reporter should be mounted at a time',
  );
  isMounted = true;

  const playbackReporter = playbackReporting({ transport });
  const stopwatch = createStopwatch();

  const reportStatus: { [key: string]: boolean } = {};
  async function report(
    status: ReportingStatusEnum,
  ): Promise<ResponsePayload | null> {
    if (reportStatus[status] || !session.profileId || !session.sessionId)
      return null;
    reportStatus[status] = true;

    const reportingResponse = await playbackReporter(
      {
        reportPayload,
        secondsPlayed: stopwatch.get(),
        stationId: String(stationId),
        stationType,
        // Don't report skip if we are reporting 15 second REPORT trigger.
        status: isSkip && status !== REPORT_15 ? SKIP : status,
      },
      session,
    );
    setReportingPayload(reportingResponse);
    return reportingResponse;
  }

  const playerUnsubscribe = player.subscribe({
    buffer({ newstate }) {
      stopwatch.stop();
      if (newstate === 'playing') {
        stopwatch.start();
      }
    },
    complete() {
      stopwatch.stop();
      report(DONE);
    },
    error: stopwatch.stop,
    idle: stopwatch.stop,
    pause: stopwatch.stop,
    play() {
      stopwatch.start();
      if (isSkip) {
        report(SKIP);
      } else {
        report(START);
      }
    },
    playbackRateChanged({ playbackRate }) {
      stopwatch.setMultiplier(playbackRate);
    },
    time() {
      if (stopwatch.get() === 15) {
        report(REPORT_15);
      }
    },
  });

  return {
    unsubscribe() {
      stopwatch.stop();
      playerUnsubscribe();
      isMounted = false;
    },
  };
}

export default createRoyaltyReporter;
