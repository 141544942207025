if (__DEV__ && module.hot) module.hot.accept();

import Analytics from 'widget/contexts/Analytics';
import countryCodes from 'constants/countryCodes';
import createSessionExpiredInterceptor from 'widget/utils/api/interceptors/sessionExpiredInterceptor';
import facebookPixelTracker from 'trackers/facebookPixel';
import ReactDOM from 'react-dom';
import Trackers from 'widget/contexts/Trackers';
import { ComponentType } from 'react';
import { comScoreTracker } from 'widget/trackers/comScore';
import { defineTrackers, TrackerConfig } from '@iheartradio/web.signal';
import { noop } from 'lodash-es';
import { ThemeProvider } from '@emotion/react';
// @ts-ignore
import I18n from 'redux-i18n/dist/component/component';
import logger, { CONTEXTS } from './logger';
import mapConfigToTrackers from 'widget/trackers/mapConfigToTrackers';
import outbrainPixelTracker from 'trackers/outbrainPixel';
import playbackStreamService from 'widget/services/playback/streams';
import Player from 'widget/contexts/Player';
import snapchatPixelTracker from 'trackers/snapchatPixel';
import themes, { Theme } from 'widget/styles/themes';
import Translation from 'widget/contexts/Translation';
import tritonLTTracker from 'widget/trackers/triton';
import { ADOBE_ANALYTICS } from 'widget/trackers/constants';
import { AxiosTransport } from 'widget/utils/transport/AxiosTransport';
import {
  createAnalytics,
  saveAnalyticsConfigToWindow,
} from 'widget/trackers/analytics';
import { DEFAULT_THEME } from 'widget/styles/constants';
import { EventTypeMap } from 'widget/trackers/types';
import { getLocale } from 'widget/utils/localization';
import {
  Playback,
  WidgetLocationConfig,
  WidgetTrackingConfig,
} from 'widget/types';
import { queryForABTestGroup } from 'widget/utils/abTestGroup';
import { Session, SessionProvider } from 'widget/utils/session';
import { TrackingStationType } from 'widget/types/widgetTrackingConfig';
import { Tracks } from 'widget/contexts/Player/types';
import type { SessionData } from './utils/session/contexts/SessionContext';
import type { TritonIds } from 'widget/trackers/triton';

const getTrackers = (
  config: Partial<WidgetLocationConfig>,
  session: any,
  hasOptedOut = false,
) => {
  const comScoreEnabled = config?.sdks?.comScore?.enabled ?? false;
  const comScoreId = config?.sdks?.comScore?.customerId ?? '';

  const fbPixelEnabled = config?.sdks?.facebook?.pixelEnabled ?? false;
  const fbPixelId = config?.sdks?.facebook?.pixelId ?? '';

  const obPixelEnabled = config?.sdks?.outbrain?.enabled ?? false;
  const obPixelId = config?.sdks?.outbrain?.pixelId ?? '';

  const snapchatPixelEnabled = config?.sdks?.snapchat?.pixel?.enabled ?? false;
  const snapchatPixelId = config?.sdks?.snapchat?.pixel?.id ?? '';

  const tritonLTEnabled = config?.sdks?.triton?.enabled ?? false;
  const tritonLTConfig = config?.sdks?.triton?.desktop ?? ({} as TritonIds);

  const trackerDefinitions = [];
  // If not client, don't initialize trackers

  if (__CLIENT__) {
    // Always initialize comScore tracker
    trackerDefinitions.push(
      comScoreTracker(comScoreEnabled, comScoreId, hasOptedOut),
    );

    // If user has not opted out, initialize all other trackers
    if (!hasOptedOut) {
      trackerDefinitions.push(
        ...[
          facebookPixelTracker(
            fbPixelId,
            fbPixelEnabled && fbPixelId.length > 0,
          ),
          outbrainPixelTracker(
            obPixelId,
            obPixelEnabled && obPixelId.length > 0,
          ),
          snapchatPixelTracker(
            snapchatPixelId,
            snapchatPixelEnabled && snapchatPixelId.length > 0,
          ),
          tritonLTTracker(tritonLTEnabled, tritonLTConfig, session),
        ],
      );
    }
  }

  return defineTrackers<EventTypeMap>(
    trackerDefinitions as Array<TrackerConfig<EventTypeMap>>,
  );
};

async function hydrate<Props>(Component: ComponentType<any>): Promise<void> {
  type InitialProps = {
    tracks: Tracks;
    stationType: typeof TrackingStationType.TALK;
  };

  type SerializedProps = {
    initialProps: Props & InitialProps;
    isMobile: boolean;
    playbackType: Playback;
    theme: Theme;
    translations: {
      [key: string]: string;
    };
    widgetLocationConfig: Partial<WidgetLocationConfig>;
    widgetTrackingConfig: Partial<WidgetTrackingConfig>;
  };

  const serializedProps: SerializedProps = {
    initialProps: {},
    isMobile: false,
    playbackType: '',
    theme: themes[DEFAULT_THEME],
    translations: {},
    widgetLocationConfig: {},
    widgetTrackingConfig: {},
  } as SerializedProps;

  try {
    Object.assign(
      serializedProps,
      // @ts-ignore
      JSON.parse(document.querySelector('#initial-props').textContent),
    );
  } catch (error: any) {
    const errObj =
      error instanceof Error ? error : (
        new Error(error.message ?? 'Failed to parse initial props from server')
      );
    logger.error(CONTEXTS.REACT, errObj.message, {}, errObj);
  }

  const {
    initialProps,
    isMobile,
    playbackType,
    theme,
    translations,
    widgetLocationConfig,
    widgetTrackingConfig,
  } = serializedProps;

  const { tracks } = initialProps;

  const {
    api,
    env = '',
    countryCode = '',
    hostName = '',
    lang = '',
  } = widgetLocationConfig;

  const baseURL = api?.client ?? '';

  const ampTransport = new AxiosTransport({
    baseURL,
    headers: {
      'X-hostName': hostName,
      'X-Locale': getLocale(lang, countryCode),
    },
  });

  const session = new Session(countryCode, ampTransport, isMobile);
  const sessionData: SessionData = await session.getSession();

  // Get privacy settings
  const privacySettings = await ampTransport.get(
    `/api/v3/privacy/accounts/${sessionData.profileId}/privacySettings`,
  );
  const { hasOptedOut = false } = privacySettings.data;

  const trackers = getTrackers(widgetLocationConfig, session, hasOptedOut);

  AxiosTransport.bindInterceptors(
    [createSessionExpiredInterceptor(session, ampTransport)],
    ampTransport.getWrappedInstance(),
  );

  // we need a plain transport for 3rd party reuqests because
  // AMP's custom headers trigger a CORS pre-flight request
  const thirdPartyTransport = new AxiosTransport();

  const analytics = createAnalytics(ampTransport, logger);

  const widgetTrackingConfigAdobeAnalytics =
    widgetTrackingConfig[ADOBE_ANALYTICS];
  if (widgetTrackingConfigAdobeAnalytics) {
    await saveAnalyticsConfigToWindow(
      session,
      baseURL,
      env,
      countryCode,
      ampTransport,
      widgetTrackingConfigAdobeAnalytics,
    );
  }

  const { stationType = TrackingStationType.TALK } = initialProps;
  // map of null returning components that should be placed below providers
  const trackingElements = mapConfigToTrackers(
    { ...widgetTrackingConfig, stationType },
    ampTransport,
  );

  const params = new URLSearchParams(window.location.search.slice(1));

  // AMP's AB test endpoint does not support WW
  // and we don't need AB test support for WW
  const abTestGroups =
    params.get('abtest') !== 'true' || countryCode === countryCodes.WW ?
      {}
    : await queryForABTestGroup({
        ampTransport,
        analytics,
        countryCode,
        lang,
        logger,
        session,
      });

  ReactDOM.hydrate(
    <I18n
      dispatch={noop}
      initialized
      lang={lang}
      translations={{
        [lang]: translations,
        options: { suppress_warnings: true },
      }}
    >
      <Translation.Provider>
        <ThemeProvider theme={theme}>
          <Analytics.Provider analytics={analytics}>
            <Trackers.Provider value={trackers}>
              <SessionProvider session={sessionData}>
                <Player.Provider
                  getStream={playbackStreamService({ transport: ampTransport })}
                  playbackType={playbackType}
                  tracks={tracks}
                  transport={thirdPartyTransport}
                >
                  {trackingElements}
                  <Component
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...initialProps}
                    abTestGroups={abTestGroups}
                    analytics={analytics}
                    isMobile={isMobile}
                    logger={logger}
                    transport={ampTransport}
                  />
                </Player.Provider>
              </SessionProvider>
            </Trackers.Provider>
          </Analytics.Provider>
        </ThemeProvider>
      </Translation.Provider>
    </I18n>,
    window.document.querySelector('#app'),
  );
}

export default hydrate;
