/**
 * Converts any value to a boolean and considers certain string values `true`
 * Useful for converting things like query params and form values to the proper boolean values
 */
const toBoolean = (x: unknown): boolean => {
  if (typeof x === 'string') {
    // Return `true` for input values "TRUE", "true", "YES", "yes"
    const truthyValues = ['true', 'yes', '1'];
    return truthyValues.includes(x.toLowerCase());
  }

  return !!x;
};

export default toBoolean;
