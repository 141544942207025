import player from 'widget/player';
import { SetStateAction, useEffect, useState } from 'react';

export type PlayerProgress = {
  duration: number;
  position: number;
};

function usePlayerProgress(
  isDragging = false,
): [{ duration: number; position: number }, (position: number) => void] {
  const [progress, setProgress] = useState<PlayerProgress>({
    duration: 0,
    position: 0,
  });

  useEffect(() => {
    function onTime(data: SetStateAction<PlayerProgress>) {
      if (isDragging) return;
      setProgress(data);
    }

    const unsubscribe = player.subscribe({ time: onTime });

    return unsubscribe;
  }, [isDragging]);

  function setPlayerProgress(position: number): void {
    setProgress({ ...progress, position });
  }

  return [progress, setPlayerProgress];
}

export default usePlayerProgress;
