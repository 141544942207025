import Analytics from 'widget/contexts/Analytics';
import getScript from 'utils/getScript';
import useMount from 'hooks/useMount';
import { AdobeAnalyticsConfig } from 'widget/types/widgetTrackingConfig';
import { get } from 'lodash-es';
import { useContext } from 'react';

// TODO Switch this out for prod Launch script when we update the prod web-config dtmUrl value
const FALLBACK_SCRIPT =
  '//assets.adobedtm.com/98de9919e92a38033805f9f035c43198533e4380/satelliteLib-1fd06645ceff19a76e6f01fc65d41e568d40ed1c.js';

export default function AdobeAnalyticsTracker({
  config,
}: AdobeAnalyticsConfig) {
  const analytics: { setReadyState: (arg0: boolean) => void } = useContext(
    Analytics.Context,
  );

  useMount(() => {
    (async () => {
      await getScript(
        get(config, ['config', 'dtmUrl']) || FALLBACK_SCRIPT,
        'dtmUrl',
        {
          async: true,
        },
      );

      if (analytics) analytics.setReadyState(true);
    })();
  });

  return null;
}
